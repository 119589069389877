@import '../../Assets/sass/settings/settings'

.Wrapper
	position: fixed
	top: 0
	left: 0
	right: 0
	bottom: 0
	width: 100vw
	height: 100vh
	background: $color-blue1
	display: flex
	flex-direction: column
	gap: 20px
	align-items: center
	justify-content: center
	& img
		max-width: 900px
	