@import "../../Assets/sass/settings/settings"
@import "../../Assets/sass/tools/breakpoints"

.Product
	&__title
		color: #565656
		margin-top: 0
		margin-bottom: 45px
		font-weight: 900
		font-size: 48px
		@include media-breakpoint-down(md)
			font-size: 40px
		@include media-breakpoint-down(sm)
			font-size: 34px
	&__main
		display: flex
		align-items: flex-start
		gap: 30px
		margin-bottom: 50px
		@include media-breakpoint-down(md)
			flex-direction: column
			width: 100%
			align-items: stretch
		&-img
			flex: 1
			& img
				width: 100%
				height: auto
				object-fit: contain
		&-description
			flex: 1
			display: flex
			flex-direction: column
			gap: 10px
			&-title
				font-size: 28px
				line-height: 1.2
				color: $color-blue1
				font-weight: 700
			&-intro
				font-size: 20px
				font-weight: 500
				margin-bottom: 10px
			&-list
				padding: 10px
				border-top: 2px solid $color-blue4
				border-bottom: 2px solid $color-blue4
				margin-bottom: 20px
				&-li
					padding-left: 5px
					padding-bottom: 4px
					padding-top: 4px
					color: #000
					font-size: 13px
					font-weight: 400
					border-bottom: 1px dotted #e28229
					opacity: .8
					&:last-of-type
						border-bottom: 0
			&-price
				font-size: 40px
				display: flex
				align-items: center
				justify-content: flex-end
				margin-top: 40px
				padding-right: 10px
			&-variants
				display: flex
				align-items: center
				gap: 10px
				&-item
					width: max-content
					font-weight: 700
					line-height: 1.15
					letter-spacing: .5px
					text-transform: uppercase
					padding: 5px 8px
					border: 1px solid $color-blue2
					border-radius: 5px
					margin-bottom: 7px
					font-size: 20px
					color: #0a2825
					cursor: pointer
					&:hover
						border-color: $color-brand-orange
						background: lighten($color-brand-orange, 40%)
					&_active
						background: $color-blue2
						color: white
						&:hover
							background: $color-blue2
							border-color: $color-blue2
	&__subTitle
		text-align: center
		text-transform: uppercase
		color: #565656
		margin-bottom: 39px
		font-weight: 700
		font-size: 35px
		@include media-breakpoint-down(md)
			font-size: 26px
	&__table
		display: grid
		grid-template-columns: max-content 1fr
		grid-auto-rows: minmax(50px, max-content)
		gap: 3px
		margin-bottom: 60px
		width: 100%
		@include media-breakpoint-down(sm)
			grid-template-columns: 1fr
		&-left
			width: max-content
			flex-shrink: 0
			gap: 5px
			display: flex
			flex-direction: column
		&-right
			flex: 1
			gap: 5px
			display: flex
			flex-direction: column
		&-row
			height: 50px
			display: flex
			width: 100%
			align-items: center
			background: #e5e5e5
			padding: 5px 10px
			flex-shrink: 0
			&-title
				display: flex
				align-items: center
				background: #e5e5e5
				padding: 5px 10px
				font-size: 16px
				text-transform: uppercase
				font-weight: 700
			&-text
				font-size: 16px
				display: flex
				align-items: center
				background: #e5e5e5
				padding: 5px 10px
				@include media-breakpoint-down(sm)
					border-left: 20px solid white
			
		