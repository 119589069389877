//Размеры шрифтов
$font-size-normal: 16px !default;
$font-size-small:14px !default;
$font-size-smaller:12px !default;
$font-size-h1:48px !default;
$font-size-h2:28px !default;
$font-size-h3:20px !default;
$font-size-h4:16px !default;


$line-height: 1.5 !default;

//Основные цвета
$color-blue1: #1a366d;
$color-blue2: rgb(39, 81, 163);
$color-blue3: rgba(39, 81, 163, 0.7);
$color-blue4: rgba(62, 107, 202);

$color-text-blue_dark: #11244a !default;

$color-basic-gray: #808080 !default;
$color-basic-black: #000000 !default;
$color-basic-white: #FFFFFF !default;

$color-brand-green: #31AC9F !default;
$color-brand-green_light: #BEDAD6 !default;
$color-brand-orange:#E28229 !default;

$color-text-green_dark: #0A2825 !default;
$color-text-green_mid: #1B675F !default;
$color-text-green_light: #8DB3AF !default;

$color-grayscale_dark: #565656 !default;
$color-grayscale_mid: #CED0D0 !default;
$color-grayscale_light: #DFE3E3 !default;

//Шрифт
$basic-font: 'Roboto' !default;
