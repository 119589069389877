@import "../../Assets/sass/settings/settings"

.MenuListItems
	display: flex
	flex-direction: column
	gap: 3px
	&__wrapper
		&:first-of-type
			margin-top: 5px
	&__el
		padding: 10px 10px 10px 5px
		font-size: 14px
		color: #535353
		text-decoration: none
		border-radius: 4px
		border: 1px solid lighten(#d8d7d7, 5%)
		background: lighten(#efefef, 5%)
		display: -ms-flexbox
		display: flex
		justify-content: space-between
		align-items: center
		border-left: 5px solid $color-blue4
		&:hover, &.active
			background: lighten(#d8d7d7, 10%)
			border-left: 5px solid $color-brand-orange
	&__children
		width: 100%
		padding-left: 5px